import React, { PropsWithChildren } from 'react'
import { FullPageErrors } from '../types/enums/Errors.enum'
import {
  appErrors,
  checkoutBasketErrors,
  checkoutBasketItemError,
  CustomError,
  vendorErrors,
} from '../utils/errors'
import ErrorPage from './error-page'

type FullPageErrorType = keyof typeof FullPageErrors
type Props = PropsWithChildren<{
  onChatToUsClick: () => void
}>
type State = {
  error?: CustomError
}

const fullPageErrors = [...appErrors, ...vendorErrors, ...checkoutBasketErrors]
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromError(error: { message: FullPageErrorType }) {
    return { error }
  }

  render() {
    const { error } = this.state

    if (error && fullPageErrors.includes(error.message)) {
      const showSearchVendorsCta = vendorErrors.includes(error.message)
      const showEditBasketCta = Boolean(
        checkoutBasketErrors.includes(error.message) && error.permalink && error.basketId,
      )
      const showViewBasketCta = checkoutBasketItemError.includes(error.message)
      return (
        <ErrorPage
          error={error}
          errorMessage={error.errorMessage || FullPageErrors[error.message]}
          showSearchVendorsCta={showSearchVendorsCta}
          showEditBasketCta={showEditBasketCta}
          showViewBasketCta={showViewBasketCta}
          onChatToUsClick={this.props.onChatToUsClick}
        />
      )
    } else {
      return this.props.children
    }
  }
}
