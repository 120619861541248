import _ from 'lodash'
import * as dateFns from 'date-fns'
import {
  runAllValidators,
  validationErrorToUserFriendlyMessage,
} from '@teamfeedr/utils--gm-validation'
import { AppErrors } from '../../types/AppErrors'
import { UserBasketSettings } from '../../domain/user-basket-settings'
import { MenuVendorLocationWithDeliveryRegionsFragment } from '@/generated/graphql'

type ValidateProps = {
  inRangeVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[]
  userState: UserBasketSettings
  basketIsFeedrAdminApproved?: boolean
  useDeliveryTimes?: boolean
}

export const getCutOffLocations = (
  locations: MenuVendorLocationWithDeliveryRegionsFragment[],
  isCutoff5pm: boolean,
) =>
  locations.map((location) => {
    if (!isCutoff5pm) return location
    const locationData = { ...location }
    const locationDetails = { ...locationData.details }
    let closedDates = locationData.closingDates?.closedDates || []
    try {
      const now = new Date()
      const currentHour = now.getHours()
      let minHours = 24
      let newClosedDate = now
      closedDates = [...closedDates, dateFns.format(newClosedDate, 'yyyy-MM-dd')]
      if (currentHour >= 17) {
        newClosedDate = dateFns.add(newClosedDate, { days: 1 })
        closedDates = [...closedDates, dateFns.format(newClosedDate, 'yyyy-MM-dd')]
      }
      const dayOfWeek = dateFns.getDay(now)
      if ([5].includes(dayOfWeek)) {
        minHours -= 24
      }
      if ([6, 0].includes(dayOfWeek) && currentHour < 17) {
        newClosedDate = dateFns.add(newClosedDate, { days: 1 })
        closedDates = [...closedDates, dateFns.format(newClosedDate, 'yyyy-MM-dd')]
      }
      if ([5, 6].includes(dayOfWeek)) {
        newClosedDate = dateFns.add(newClosedDate, { days: 1 })
        closedDates = [...closedDates, dateFns.format(newClosedDate, 'yyyy-MM-dd')]
        if (dayOfWeek === 5) {
          newClosedDate = dateFns.add(newClosedDate, { days: 1 })
          closedDates = [...closedDates, dateFns.format(newClosedDate, 'yyyy-MM-dd')]
        }
      }
      minHours -= currentHour
      locationDetails.gmMinNoticePeriod = minHours
    } catch (e) {
      console.error('Error setting cutoff locations', e)
    }
    return {
      ...locationData,
      closingDates: {
        ...locationData.closingDates,
        closedDates,
      },
      details: locationDetails,
    }
  })

export const validateAll = ({
  inRangeVendorLocations,
  userState,
  basketIsFeedrAdminApproved,
  useDeliveryTimes = false,
}: ValidateProps): {
  newErrors: AppErrors
  chosenVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[]
} => {
  const inRangeLocations = _.cloneDeep(inRangeVendorLocations)

  const responses = runAllValidators({
    userState,
    inRangeLocations,
    basketIsFeedrAdminApproved,
    useDeliveryTimes,
  })
  const errors = _.mapValues(responses, (response) =>
    response?.result.type === 'error'
      ? validationErrorToUserFriendlyMessage(response.result.payload)
      : null,
  )
  const chosenVendorLocations = responses.time?.newLocations || responses.address.newLocations
  return {
    newErrors: _.omitBy(errors, _.isNil),
    chosenVendorLocations,
  }
}
