export enum DateErrors {
  INVALID_DATE = 'Enter a valid date',
  CLOSED = 'Vendor is closed',
  // We want the same error text but we need different values
  // so we can differentiate between the two in code. Hence the space at the end
  CLOSED_LARGE_ORDER = 'Vendor is closed ',
  DATE_PAST = "This date isn't available anymore.",
}

export enum AddressErrors {
  INVALID_ADDRESS = 'Please enter a valid address.',
  VENDER_COVERAGE = 'This vendor doesn’t serve this location.',
  NO_STREET = 'Please enter a street address.',
}

export enum TimeErrors {
  INVALID_TIME = 'Enter a valid time',
  TIME_PAST = "This timeslot isn't available anymore.",
}

export enum HeadCountErrors {
  INVALID_HEAD_COUNT = 'Enter headcount',
  PAST_THRESHOLD = 'This vendor requires a greater lead time in order to fulfil an order of this size.',
  TOO_LOW = 'This vendor cannot fulfil an order of this size.',
  TOO_HIGH = 'This vendor cannot fulfil an order of this size.',
}

export enum FullPageErrors {
  COULD_NOT_FETCH_VENDORS = 'Could not load vendors',
  REACT_CONTEXT_USED_OUTSIDE_PROVIDER = 'Context used outside provider',
  COULD_NOT_FETCH_VENDOR = 'Could not find vendor',
  COULD_NOT_FETCH_VENDOR_CURRENCY = 'Could not find vendor currency',
  COULD_NOT_FETCH_VENDOR_PERMALINK = 'Could not find vendor permalink',
  COULD_NOT_FETCH_AVAILABILITY_FOR_VENDOR = 'Could not find vendor menu',
  COULD_NOT_FETCH_CHECKOUT_BASKET = 'This basket no longer exists, please check your dashboard',
  CHECKOUT_BASKET_ITEMS_CHANGED = 'Please review these changes to continue with your order',
  COULD_NOT_FIND_MENU_ITEM_OF_BASKET_ITEM = 'could not find menu item',
  VENDOR_LOCATION_DOES_NOT_MATCH_VENDOR = 'Vendor location does not match vendor',
  CHECKOUT_VALIDATION_FAILED = 'Validation failed',
  CHECKOUT_BASKET_ACCOUNT_INVALID_FOR_USER = 'This basket id does not belong to you',
}

export enum BasketErrors {
  COULD_NOT_FETCH_BASKET = 'could not load your basket',
  COULD_NOT_CREATE_BASKET = 'could not create your basket',
  COULD_NOT_UPDATE_BASKET = 'could not update your basket',
  COULD_NOT_ADD_ITEM_TO_BASKET = 'could not add item to your basket',
  COULD_NOT_UPDATE_ITEM_IN_BASKET = 'could not update item in your basket',
  COULD_NOT_UPDATE_ITEM_QTY_IN_BASKET = 'could not update item quantity in your basket',
  COULD_NOT_REMOVE_ITEM_FROM_BASKET = 'could not remove item from your basket',
}
