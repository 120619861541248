import React from 'react'
import { match, P } from 'ts-pattern'
import { AuthGmQuery, useAuthGmQuery } from '@/generated/graphql'
import { useAppError } from '../../utils/errors'
import { isUnauthenticatedGraphQLError } from '@/helpers/apollo/ApolloClient'

type LoadingAuthState = { type: 'loading' }
export type LoadedAuthState =
  | { type: 'authenticated'; user: AuthGmQuery['me'] }
  | { type: 'unauthenticated'; reload: () => void }
type AuthState = LoadingAuthState | LoadedAuthState

const AuthStateContext = React.createContext<AuthState | null>(null)

// @ts-expect-error unknown
export const AuthStateProvider: React.FC = ({ children }) => {
  const authQuery = useAuthGmQuery()

  const state = match(authQuery)
    .with({ loading: true }, (): AuthState => ({ type: 'loading' }))
    .when(
      ({ error }) => error?.graphQLErrors.some(isUnauthenticatedGraphQLError),
      (): AuthState => ({ type: 'unauthenticated', reload: authQuery.refetch }),
    )
    .with({ error: P.not(P.nullish) }, ({ error }) => {
      throw error
    })
    .with(
      { data: { me: { account: P.not(P.nullish) } } },
      ({ data: { me } }): AuthState => ({
        type: 'authenticated',
        user: me,
      }),
    )
    .otherwise((): AuthState => ({ type: 'unauthenticated', reload: authQuery.refetch }))

  return <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
}

export const useAuthState = (): AuthState => {
  const { throwAppError } = useAppError()
  const state = React.useContext(AuthStateContext)

  if (!state)
    return throwAppError({ type: 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER', name: 'AuthState' })

  return state
}
