const BrokenBowl = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={253}
    height={206}
    fill="none"
    viewBox="0 0 253 206"
  >
    <path
      fill="#CFD7D9"
      d="M35.007 144.475c-11.07-3.928 18.309-5.461 34.049-5.461h84.801c49.083 0 46.577 3.64 39.189 5.461-21.843 2.462-48.889 7.002 17.667 5.46 49.631-1.149 47.213 5.727 33.716 12.051-10.289 4.821-21.812 5.98-33.147 6.76-17.049 1.174-30.011 3.415-4.745 6.887 42.08 5.782-17.346 10.6-106.323 9.636-49.762-.539-61.398-5.504-59.688-10.09 2.215-5.94 11.041-5.339 16.753-8.09 3.213-1.548 1.689-2.855-13.92-2.378-31.48.964-70.026-11.885-17.347-12.848 52.68-.964 18.952-3.855 8.995-7.388z"
      opacity={0.2}
    />
    <path
      fill="#C2CACC"
      fillRule="evenodd"
      d="M170.331 127.112c.103-.134.201-.272.295-.414 6.41-9.673-11.805-33.026-40.684-52.161-28.878-19.135-57.484-26.805-63.894-17.132a7.29 7.29 0 00-.844 1.742c-11.105 20-22.968 63.557 13.718 87.865 17.817 12.448 61.035 25.886 91.409-19.9z"
      clipRule="evenodd"
    />
    <path
      fill="#CFD7D9"
      d="M129.056 75.42c-53.672 17.614-69.053 111.077 15.41 62.231 21.999-2.378 50.355-18.655-15.41-62.23z"
    />
    <path
      fill="url(#prefix__paint0_linear)"
      d="M122.442 114.099c2.977 3.271 5.555 6.625 7.57 9.808 4.395 6.946 10.606 16.836 9.121 24.92-.823 4.477-4.385 8.095-12.819 6.569-83.325-21.842-64.77-90.726-45.934-114.85 13.429-17.2 23.809-23.775 32.516-25.072 6.512-.97 14.062.903 17.746 6.36.596.881.937 1.764 1.058 2.642 2.407 17.306-36.898 22.354-30.369 38.561 2.11 5.238 8.936 7.721 14.444 8.402 7.344.895 4.747 11.773 1.454 21.723-2.441 7.373-.016 15.193 5.213 20.937z"
    />
    <path
      fill="#C2CACC"
      stroke="#CFD7D9"
      strokeWidth={0.642}
      d="M150.607 129.546l.168-.231-.209-.194-3.397-3.16-.284-.264-.207.328-8.771 13.957-9.22-.062h-.08l-.071.037-12.677 6.628-.059.032-.043.052-5.548 6.92.398.486 20.166-10.443 9.229.195.167.004.099-.135 10.339-14.15z"
    />
    <path
      fill="#A5AEAF"
      fillRule="evenodd"
      d="M78.922 147.012C42.237 122.704 54.1 79.148 65.204 59.148c-3.5 10.521 14.26 32.35 41.529 50.418 6.517 4.318 13.02 8.052 19.257 11.135l-.044.413-3.297 15.734-7.363 11.112 12.986-7.387 9.788.059 7.541-12.116c11.961 3.455 21.156 3.241 24.731-1.406-30.374 45.789-73.592 32.35-91.41 19.902z"
      clipRule="evenodd"
    />
    <path
      fill="#E3EAEC"
      fillRule="evenodd"
      d="M126.101 120.756c-6.271-3.093-12.813-6.846-19.369-11.19-28.878-19.135-47.093-42.488-40.683-52.161 6.409-9.673 35.015-2.003 63.893 17.132 28.879 19.135 47.094 42.488 40.684 52.161-2.918 4.405-10.439 5.213-20.492 2.981l.189-.286-3.458-3.286c10.278 2.769 17.969 2.387 20.746-1.804 5.727-8.642-11.61-30.21-38.722-48.175-27.113-17.965-53.734-25.523-59.46-16.88-5.726 8.642 11.61 30.211 38.723 48.176 6.553 4.341 13.077 8.075 19.284 11.119l-1.066-.397-.269 2.61z"
      clipRule="evenodd"
    />
    <path
      fill="#C2CACC"
      d="M150.348 129.356l-3.398-3.16-8.866 14.108-9.399-.062-12.677 6.628-5.549 6.92 20.239-10.48 9.31.196 10.34-14.15z"
    />
    <path
      fill="#A5AEAF"
      d="M104.47 39.178c13.015-4.845 35.494-14.247 19.58-22.402a11.527 11.527 0 00-2.799-1.005c-10.357-2.242-22.877 1.729-40.87 24.775-2.57 2.842-10.038 15.051-9.525 19.677 6.318-.214 20.962 1.055 26.102 4.91 4.368 3.275 4.286-1.05 3.957-3.419a15.497 15.497 0 00-.174-1.018c-.478-4.2-7.416-9.805-2.587-17.139 1.442-2.189 3.859-3.465 6.316-4.38z"
    />
    <path
      fill="#A5AEAF"
      d="M105.167 41.19c11.128-4.055 29.612-13.703 17.396-20.288a10.012 10.012 0 00-2.927-1.022c-8.861-1.634-19.714 2.284-35.593 22.867-2.282 2.573-8.998 13.55-8.65 17.638 5.47-.322 12.943 8.006 17.309 11.314 3.757 2.848 2.426-2.906 1.819-5.476-.098-.416-.178-.749-.213-.948-.31-3.53-1.755-11.354 2.694-18.522 1.785-2.877 4.984-4.404 8.165-5.563z"
    />
    <path
      fill="url(#prefix__paint1_linear)"
      d="M105.167 41.19c11.128-4.055 29.612-13.703 17.396-20.288a10.012 10.012 0 00-2.927-1.022c-8.861-1.634-19.714 2.284-35.593 22.867-2.282 2.573-8.998 13.55-8.65 17.638 5.47-.322 12.943 8.006 17.309 11.314 3.757 2.848 2.426-2.906 1.819-5.476-.098-.416-.178-.749-.213-.948-.31-3.53-1.755-11.354 2.694-18.522 1.785-2.877 4.984-4.404 8.165-5.563z"
    />
    <path
      fill="#CFD7D9"
      d="M183.33 152.184c-1.156.449-13.438 2.061-19.434 2.81 10.493 4.337 32.797 12.994 38.065 12.929 5.268-.064 14.133-10.198 17.908-15.257l-.803-7.71-25.859-.963c-2.81 2.543-8.721 7.741-9.877 8.191z"
    />
    <path
      fill="#C2CACC"
      d="M202.04 168.164l-1.686-5.541c7.773-.963 15.766-12.179 18.791-17.667l.883 7.388c-8.673 11.95-15.605 15.526-17.988 15.82z"
    />
    <path
      fill="#A5AEAF"
      d="M163.815 157.163v-2.41c21.586 9.765 33.889 9.262 37.021 7.79l1.525 5.461c-15.097 2.441-32.202-6.211-38.546-10.841z"
    />
    <path
      fill="#CFD7D9"
      d="M145.104 162.784v-2.489l10.279-3.213 8.994 9.798v2.007l-19.273-6.103z"
    />
    <path
      fill="#A5AEAF"
      d="M145.104 162.784v-2.489l2.731.803 4.818 1.686 11.724 4.096v2.007l-19.273-6.103z"
    />
    <path
      fill="#B2BABB"
      d="M142.131 43.232c-6.585-11.564-16.061-2.303-18.149 2.57 3.598 0 7.174 1.391 8.513 2.087 10.15 3.726 10.654-1.552 9.636-4.657z"
    />
    <path
      fill="#B3BABB"
      d="M147.117 31.788c0-7.865-7.576-5.88-10.073-3.987 1.848 1.052 3.277 2.813 3.761 3.562 4.124 4.882 5.927 2.318 6.312.425z"
    />
    <path fill="#CFD7D9" d="M104.893 169.329L.818 159.05l97.008 13.491 7.067-3.212z" />
    <path fill="#C2CACC" d="M105.535 175.432v-6.103l-7.388 3.212v6.103l7.388-3.212z" />
    <path fill="#A5AEAF" d="M98.147 178.725v-6.505l-96.37-13.06 96.37 19.565z" />
    <path fill="#CFD7D9" d="M117.566 179.579l-104.385 6.39 97.919-2.095 6.466-4.295z" />
    <path fill="#C2CACC" d="M119.171 185.503l-.97-6.026-6.784 4.346.97 6.025 6.784-4.345z" />
    <path fill="#A5AEAF" d="M112.4 189.928l-1.034-6.422-97.222 2.42 98.256 4.002z" />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={115.151}
        x2={125.269}
        y1={82.158}
        y2={143.029}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A5AEAF" />
        <stop offset={1} stopColor="#C2CACC" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={117.033}
        x2={99.148}
        y1={13.546}
        y2={64.381}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B3B9BA" />
        <stop offset={1} stopColor="#C2CACC" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)

export default BrokenBowl
