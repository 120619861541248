import { useCallback, useEffect } from 'react'
import * as dateFns from 'date-fns'
import { computeDeliveryTimeSlots, UserBasketSettings } from '../../domain/user-basket-settings'
import { userStateDateTimeToUTCString } from '@/components/page-specific/gm/helpers/date'
import { Settable } from '../../utils/typing'
import { useAppState } from './app'
import { useAuthState } from './auth'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

type Props = {
  /**
   * Pass this to reset date/time settings if they are before certain point
   * (e.g. before notice period)
   */
  resetDateTimeBefore?: { date: Date }
}

type State = {
  settings: Settable<UserBasketSettings>
}

export const useUserBasketSettingsState = (props?: Props): State => {
  const { userState: settings, updateUserState: setSettings } = useAppState()
  const authState = useAuthState()
  const useNewSavedAddresses = useFeatureFlag(SPLITS.USE_NEW_SAVED_ADDRESSED)
  const isCutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)

  const updateSettings = useCallback(
    (newSettings: UserBasketSettings) => {
      const validNewSettings = {
        ...newSettings,
        ...((!newSettings.date || !newSettings.time) &&
          newSettings.headCount && {
            headCount: undefined,
          }),
      }
      setSettings(validNewSettings)
    },
    [setSettings],
  )
  useEffect(() => {
    if (!useNewSavedAddresses) return
    if (authState.type !== 'authenticated') return
    if (settings.location?.__typename !== 'Address') return
    if (!authState.user.account?.locations?.length) return
    const matchedAccountLocation = authState.user.account?.locations.find(
      (loc) =>
        settings.location?.__typename === 'Address' &&
        loc?.address.latitude?.toFixed(4) === settings.location.latitude?.toFixed(4) &&
        loc?.address.longitude?.toFixed(4) === settings.location.longitude?.toFixed(4),
    )
    if (!matchedAccountLocation) return
    updateSettings({
      ...settings,
      location: matchedAccountLocation,
    })
  }, [authState, settings, updateSettings, useNewSavedAddresses])

  useEffect(() => {
    if (!settings || !settings.date || !props || !props.resetDateTimeBefore) return

    const { resetDateTimeBefore } = props

    const selectedDateTime = new Date(userStateDateTimeToUTCString(settings.date, settings.time))
    if (dateFns.isAfter(selectedDateTime, resetDateTimeBefore.date)) return

    const selectableTimesOnTheDay = computeDeliveryTimeSlots({
      deliveryDate: new Date(settings.date),
      minAllowedDateTime: resetDateTimeBefore.date,
      useDeliveryTimes: isCutOff5pm,
    })

    updateSettings({
      ...settings,
      ...(selectableTimesOnTheDay.length === 0 && { date: undefined }),
      ...(settings.time && { time: undefined }),
    })
  }, [props, settings, updateSettings])

  return {
    settings: { value: settings, set: updateSettings },
  }
}
