import { useCallback } from 'react'
import { FullPageErrors } from '../types/enums/Errors.enum'

export const appErrors = ['COULD_NOT_FETCH_VENDORS', 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER']
export const vendorErrors = [
  'VENDOR_NOT_ACTIVE',
  'COULD_NOT_FETCH_VENDOR',
  'COULD_NOT_FETCH_VENDOR_CURRENCY',
  'COULD_NOT_FETCH_VENDOR_PERMALINK',
  'COULD_NOT_FETCH_AVAILABILITY_FOR_VENDOR',
]
export const checkoutBasketErrors = [
  'COULD_NOT_FETCH_CHECKOUT_BASKET',
  'CHECKOUT_BASKET_ITEMS_CHANGED',
  'VENDOR_LOCATION_DOES_NOT_MATCH_VENDOR',
  'CHECKOUT_VALIDATION_FAILED',
  'CHECKOUT_BASKET_ACCOUNT_INVALID_FOR_USER',
]
export const checkoutBasketItemError = ['CHECKOUT_BASKET_ITEMS_CHANGED']

export type AppError = (
  | { type: 'VENDOR_NOT_ACTIVE'; vendorId: string }
  | { type: 'COULD_NOT_FETCH_VENDORS' }
  | { type: 'COULD_NOT_FETCH_VENDOR' }
  | { type: 'COULD_NOT_FETCH_VENDOR_CURRENCY'; vendorId: string }
  | { type: 'COULD_NOT_FETCH_VENDOR_PERMALINK'; vendorId: string }
  | { type: 'COULD_NOT_FETCH_AVAILABILITY_FOR_VENDOR'; vendorId: string }
  | { type: 'VENDOR_LOCATION_DOES_NOT_MATCH_VENDOR' }
  | {
      type: 'COULD_NOT_FIND_MENU_ITEM_OF_BASKET_ITEM'
      itemReference: string
    }
  | { type: 'COULD_NOT_FETCH_CHECKOUT_BASKET' }
  | { type: 'CHECKOUT_VALIDATION_FAILED' }
  | { type: 'CHECKOUT_BASKET_ITEMS_CHANGED' }
  | { type: 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER'; name: string }
  | { type: 'CHECKOUT_BASKET_ACCOUNT_INVALID_FOR_USER' }
) & { cause?: unknown; basketId?: string; permalink?: string; errorMessage?: string }

export type CustomError = Error & {
  errorMessage?: string
  message: keyof typeof FullPageErrors
  basketId?: string
  permalink?: string
}

const customError = (error: AppError) => {
  const thrownError = new Error(error.type) as CustomError
  if (error.basketId) thrownError.basketId = error.basketId
  if (error.permalink) thrownError.permalink = error.permalink
  if (error.errorMessage) thrownError.errorMessage = error.errorMessage
  return thrownError
}

export const useAppError = (): {
  throwAppError: (error: AppError) => never
} => {
  const throwAppError = useCallback((error: AppError) => {
    throw customError(error)
  }, [])

  return { throwAppError }
}
