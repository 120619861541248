import _ from 'lodash'
import { z } from 'zod'

const LOCAL_STORAGE_KEY = 'gm__basket-ids'

const basketIdSchema = z.string().min(1)
// map of vendor ID to local basket ID, e.g. { nusa: "basket123" }
const basketIdsSchema = z.record(basketIdSchema.optional())
type BasketIds = z.infer<typeof basketIdsSchema>

const retrieveBasketIds = (): BasketIds => {
  const rawIds: string = localStorage[LOCAL_STORAGE_KEY] || '{}'
  return basketIdsSchema.parse(JSON.parse(rawIds))
}
const storeBasketIds = (basketIds: BasketIds) => {
  localStorage[LOCAL_STORAGE_KEY] = JSON.stringify(basketIds)
}

export const getLocalBasketIdForVendor = (vendorId: string): string | undefined =>
  retrieveBasketIds()[vendorId]
export const setLocalBasketIdForVendor = (vendorId: string, basketId: string): void =>
  storeBasketIds({
    ...retrieveBasketIds(),
    [vendorId]: basketId,
  })
export const getAllLocalBasketIds = () => _.compact(Object.values(retrieveBasketIds()))
export const clearAllLocalBasketIds = () => storeBasketIds({})
