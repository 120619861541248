import { Currency } from '@teamfeedr/utils--money'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useCounter } from 'react-use'
import { z } from 'zod'
import {
  useGetCheckoutBasketLazyQuery,
  useUpdateCheckoutBasketMutation,
  UpdateGmBasketInput,
  AssignBasketToGuestInput,
  useAssignBasketToGuestMutation,
} from '@/generated/graphql'
import { useLanguage, useRouter } from '@/components/Link'
import { validateBasketItems } from '@teamfeedr/utils--gm-validation'
import { useAppError } from '../../utils/errors'
import { populateBasketTranslationFields } from '../../helpers/translations'
import { useAppState } from './app'
import { LoadedAuthState } from './auth'
import { useUtmData } from '@/hooks/useUtmData'
import { zonedTimeToUtc } from 'date-fns-tz'

type ChipBasketState = {
  itemCount: number
  subtotal: number
  currency: Currency
}

export type BasketState = {
  basket: ChipBasketState | null
  isOpen: boolean
  newItemWasJustAdded: boolean
  setBasket: (basket: ChipBasketState) => void
  clear: () => void
  toggleIsOpen: (display: boolean) => void
  declareNewItemWasAdded: () => void
}

const BasketStateContext = createContext<BasketState | null>(null)
export type UpdateBasket = (basketId: string, basketPayload: UpdateGmBasketInput) => void

// @ts-expect-error unknown
export const BasketStateProvider: React.FC = ({ children }) => {
  const [chipBasketState, setChipBasketState] = useState<ChipBasketState | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [newItemCount, newItemCountState] = useCounter(0)

  const state: BasketState = {
    basket: chipBasketState,
    isOpen,
    newItemWasJustAdded: newItemCount > 0,
    setBasket: (basket: ChipBasketState) => {
      setChipBasketState(basket)
    },
    clear: () => {
      setChipBasketState(null)
      setIsOpen(false)
    },
    toggleIsOpen: (display) => {
      setIsOpen(display)
      newItemCountState.reset()
    },
    declareNewItemWasAdded: () => {
      newItemCountState.inc()
      setTimeout(() => newItemCountState.dec(), 2000)
    },
  }

  return <BasketStateContext.Provider value={state}>{children}</BasketStateContext.Provider>
}

export const useBasketState = (): BasketState => {
  const { throwAppError } = useAppError()
  const state = useContext(BasketStateContext)

  if (!state)
    return throwAppError({ type: 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER', name: 'BasketState' })

  return state
}

const routerQuerySchema = z.object({ id: z.string().min(1) })

export const useBasketData = (authState?: LoadedAuthState) => {
  const language = useLanguage()
  const router = useRouter()
  const appState = useAppState()
  const utmData = useUtmData()
  const { throwAppError } = useAppError()
  const [runUpdateBasket, updateBasketResult] = useUpdateCheckoutBasketMutation()
  const [runGuestAssignment, guestAssignmentResult] = useAssignBasketToGuestMutation()
  const [runGetBasket, getBasketResult] = useGetCheckoutBasketLazyQuery()

  const basket = getBasketResult.data?.basket
    ? populateBasketTranslationFields(getBasketResult.data.basket, language)
    : null

  useEffect(() => {
    if (!router.isReady || !router.query.id) return
    const { id } = routerQuerySchema.parse(router.query)
    runGetBasket({ variables: { id } })
  }, [router.isReady, router.query, runGetBasket])

  useEffect(() => {
    if (authState?.type === 'authenticated' && basket?.settings.account?.id) {
      const basketAndUserAccountAreTheSame =
        Number(authState.user.account?.id) === Number(basket.settings.account.id)
      if (!basketAndUserAccountAreTheSame) {
        return throwAppError({
          type: 'CHECKOUT_BASKET_ACCOUNT_INVALID_FOR_USER',
          basketId: basket.id,
        })
      }
    }
  }, [authState?.type, basket?.settings.account?.id])

  useEffect(() => {
    if (basket) {
      const validated = validateBasketItems(
        basket.items,
        zonedTimeToUtc(basket.settings.deliveryDate, 'Europe/London'),
      )
      if (validated.result.type === 'error')
        return throwAppError({
          type: 'CHECKOUT_BASKET_ITEMS_CHANGED',
          basketId: basket.id,
          permalink: basket.vendor.permalink || '',
        })
    }
  }, [basket])

  useEffect(() => {
    if (updateBasketResult.data?.basket.settings.location) {
      appState.updateUserState({ location: updateBasketResult.data?.basket.settings.location })
    }
  }, [updateBasketResult])

  const updateBasket: UpdateBasket = (basketId, basketPayload) => {
    void runUpdateBasket({
      variables: {
        basketId,
        basketPayload: {
          ...basketPayload,
          utmData,
        },
      },
    })
  }

  const assignBasketToGuest = (basketId: string, input: AssignBasketToGuestInput) => {
    try {
      void runGuestAssignment({ variables: { basketId, input } })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    basket,
    updateBasket,
    assignBasketToGuest,
    getBasketResult,
    updateBasketResult,
    guestAssignmentResult,
  }
}
