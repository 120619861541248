import React, { useState } from 'react'
import styled from 'styled-components'
import { match } from 'ts-pattern'
import { main } from '@teamfeedr/ui--theme'
import Icon from '@teamfeedr/ui--icon'
import { useAuthState } from '../states/auth'
import { NavListItem } from './index.styles'
import { loggedInLinks } from './constants'
import { Button } from '@mui/material'
import Link, { useRouter } from '@/components/Link'
import RequestAQuoteButton from './requestAQuoteButton'

const BurgerButton = styled.button`
  background: ${main.colors.canvas};
  border: 0;
  padding: 0;
  margin: 0 0 0 6px;
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MobileNav = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: ${main.colors.background_lightest};

  a {
    text-decoration: none;
  }
`

const MobileNavItem = styled.span`
  width: 100%;
  margin: auto;
  padding: 24px;
  text-align: center;
  background: ${main.colors.canvas};
  font-size: ${main.fontSizes.base};
  font-weight: ${main.fontWeights.regular};
  border-top: 1px solid ${main.colors.background_lightest};
  color: ${(p) => p.color || main.colors.text};
`

const Mobile: React.FC = () => {
  const router = useRouter()
  const authState = useAuthState()
  const [open, setOpen] = useState(false)

  return match(authState)
    .with({ type: 'authenticated' }, () => (
      <>
        <BurgerButton onClick={() => setOpen(!open)}>
          <Icon
            icon={open ? 'close' : 'burger'}
            fill={main.colors[open ? 'primary' : 'text']}
            size={open ? 16 : 27}
          />
        </BurgerButton>
        {open && (
          <MobileNav>
            {loggedInLinks.map((link) => (
              <Link href={link.url} key={link.name}>
                <NavListItem>
                  <MobileNavItem color={link.color}>{link.name}</MobileNavItem>
                </NavListItem>
              </Link>
            ))}
          </MobileNav>
        )}
      </>
    ))
    .with({ type: 'loading' }, { type: 'unauthenticated' }, () => (
      <>
        <RequestAQuoteButton />
        <Link href={`/authentication/login?destination=${router.asPath}`} passHref>
          <Button variant="contained" size="small">
            Sign in
          </Button>
        </Link>
      </>
    ))
    .exhaustive()
}

export default Mobile
