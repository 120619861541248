import { main } from '@teamfeedr/ui--theme'
import config from '@/config'

export const loggedInLinks = [
  {
    name: 'My dashboard',
    url: '/dashboard',
  },
  {
    name: 'Try Canteen?',
    url: config.URI_CANTEEN,
  },
  {
    name: 'Log out',
    url: '/authentication/logout',
    color: main.colors.error,
  },
]

export const loggedInManagerLinks = [
  {
    name: 'My dashboard',
    url: '/dashboard',
  },
  {
    name: 'Admin Dashboard',
    url: `${config.URI_CANTEEN}/ca`,
  },
  {
    name: 'Try Canteen?',
    url: config.URI_CANTEEN,
  },
  {
    name: 'Log out',
    url: '/authentication/logout',
    color: main.colors.error,
  },
]
