import moment from 'moment'

export const formatDateDDMMYY = (dateTime?: Date | number) => {
  if (!dateTime) return ''
  const date = new Date(dateTime)
  if (!moment(date).isValid()) return ''
  const day = `${date.getDate()}`.padStart(2, '0')
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const year = date.getFullYear().toString().slice(-2)
  return `${day}/${month}/${year}`
}

// Datepicker starts on Sunday!
export const datepickerFixedDays = [7, 1, 2, 3, 4, 5, 6]

const getDate = (date: Date | string) => {
  if (typeof date === 'string') date = new Date(date)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

const getTimeFromDate = (date: Date | string) => getDate(date).getTime()

export const getDay = (date: Date | string) => datepickerFixedDays[getDate(date).getDay()]

export const dateIsInDates = (date: Date | string, dates: Date[] | string[]) =>
  dates.some((d) => getTimeFromDate(d) === getTimeFromDate(date))

export const userStateTimeToHoursAndMins = (time = '00:00 - 00:00') => {
  const startTime = time.split(' - ')[0]
  const [hours, minutes] = startTime.split(':')
  return { hours, minutes }
}

export const userStateDateTimeToUTCString = (date: number, time = '00:00 - 00:00') => {
  const { hours, minutes } = userStateTimeToHoursAndMins(time)
  return moment(date)
    .set({ hour: 0, minute: 0 })
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .utc(true)
    .toISOString()
}
