import { useEffect, useState } from 'react'

export type UtmData = {
  source: string
  medium: string
  campaign: string
}

export const useUtmData = () => {
  const [utmData, setUtmData] = useState<UtmData | null>(null)

  const waitForSourcing = (i: number) => {
    if (i >= 10) return
    // @ts-expect-error external script
    if (typeof visitData !== 'undefined') {
      // @ts-expect-error external script
      // eslint-disable-next-line no-undef
      const u = visitData.get()
      setUtmData({
        source: u.source || 'direct',
        medium: u.medium || 'none',
        campaign: u.campaign || '',
      })
    } else {
      setTimeout(() => waitForSourcing(i + 1), 1000)
    }
  }

  useEffect(() => {
    try {
      waitForSourcing(0)
    } catch (e) {
      console.error('Failed to load utm data.', e)
    }
  }, [])

  return utmData
}
