import { useTenantStrict } from '@/hooks/useTenant'
import { CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { match } from 'ts-pattern'

type Props = {
  size?: 'small' | 'large'
}

const LoadingIndicator: React.FC<Props> = ({ size = 'large' }) => {
  const tenant = useTenantStrict()
  const [isMounted, updateMount] = useState(false)
  useEffect(() => {
    updateMount(true)
  }, [])

  const loadingIndicator = tenant?.marketing.logos.loadingIndicator

  const margin = match(size)
    .with('large', () => '100px auto')
    .with('small', () => 0)
    .exhaustive()

  return (
    <div style={{ margin, textAlign: 'center' }}>
      {(!loadingIndicator || !isMounted) && <CircularProgress />}
      {loadingIndicator && isMounted && <img src={loadingIndicator} alt="Loading indicator" />}
    </div>
  )
}

export default LoadingIndicator
