import _ from 'lodash'

export type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>
}

export type Replace<T, U> = Omit<T, keyof U> & U

export type RequireFields<T, U extends keyof T> = {
  [K in keyof T]: K extends U ? NonNullable<T[K]> : T[K]
}

export const isNotNil = <T>(value: T | null | undefined): value is T => _.negate(_.isNil)(value)

export const areFieldsNotNil =
  <Field extends string>(fields: Field[]) =>
  <T extends Record<Field, unknown>>(input: T): input is RequireFields<T, Field> =>
    fields.every((f) => isNotNil(input[f]))

export type Settable<T> = {
  value: T
  set: (value: T) => void
}
