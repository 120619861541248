import { MenuItemFragment, BasketItemFragment, Language } from '@/generated/graphql'
import { populateFieldsWithTranslations } from '@teamfeedr/utils--translations'

export const populateBasketTranslationFields = <T extends { items: BasketItemFragment[] }>(
  basket: T,
  language: Language,
): T => {
  return {
    ...basket,
    items: (basket.items || []).map((item) => ({
      ...item,
      item: item.item && populateFieldsWithTranslations(item.item, language),
      options: item.options.map(
        (option) =>
          option && {
            ...option,
            item: option.item && populateFieldsWithTranslations(option.item, language),
          },
      ),
    })),
  }
}

export const populateItemsTranslationFields = (
  items: (MenuItemFragment | null)[],
  language: Language,
): (MenuItemFragment | null)[] => {
  return items.map((item) => {
    if (!item) return null
    const translatedOptions = (item.options || []).map(
      (option) =>
        option && {
          ...option,
          items: (option.items || []).map(
            (item) =>
              item && {
                ...item,
                item: item.item && populateFieldsWithTranslations(item.item, language),
              },
          ),
        },
    )
    return { ...populateFieldsWithTranslations(item, language), options: translatedOptions }
  })
}
