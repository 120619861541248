import { useApolloClient } from '@apollo/client'
import _, { sortBy } from 'lodash'
import { useLocale, useRouter } from '@/components/Link'
import moment from 'moment'
import { z } from 'zod'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import {
  MenuVendorLocationWithDeliveryRegionsFragment,
  ServiceType,
  UserBasketSettingsLocationFragment,
} from '@/generated/graphql'
import { lookupAddressDetails } from '@/components/page-specific/gm/helpers/addresses'
import { useAppError } from '../../utils/errors'
import { UserBasketSettings } from '../../domain/user-basket-settings'
import { getCutOffLocations, validateAll } from '@/components/page-specific/gm/helpers/validation'
import { AppErrors } from '../../types/AppErrors'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

type AppState = {
  userState: UserBasketSettings
  updateUserState: (userState: UserBasketSettings) => void
  selectedVendorLocation?: MenuVendorLocationWithDeliveryRegionsFragment
  deliveryFee?: number
  minOrderValue: number | null
  minOrderValueExTax: number | null
  availableVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[]
  setSelectedVendorLocation: (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => void
  updateDeliveryFee: (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => void
  updateMinOrderValue: (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => void
  setInRangeVendorLocations: (vendors: MenuVendorLocationWithDeliveryRegionsFragment[]) => void
  setAvailableVendorLocations: (
    availableVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[],
  ) => void
  /**
   * Returns if errors are present
   * (true: errors are not present (it validated), false: errors are present (not valid))
   */
  validate: () => boolean
  errors: AppErrors
  setErrors: (error: AppErrors) => void
  setBasketIsFeedrAdminApproved: (value: boolean) => void
}

const AppStateContext = createContext<AppState | null>(null)

const defaultUserState = {}

const routerQuerySchema = z.object({
  postcode: z.string().min(3).optional(),
  street: z.string().min(3).optional(),
  date: z.string().min(10).optional(),
  time: z.string().min(5).optional(),
})

const useAppStateImplementation = (): AppState => {
  const [errors, setErrors] = useState<AppErrors>({})
  const [selectedVendorLocation, setSelectedVendorLocation] =
    useState<MenuVendorLocationWithDeliveryRegionsFragment>()
  const [deliveryFee, setDeliveryFee] = useState<number>(15)
  const [minOrderValue, setMinOrderValue] = useState<number | null>(null)
  const [minOrderValueExTax, setMinOrderValueExTax] = useState<number | null>(null)
  const [inRangeVendorLocations, setInRangeVendorLocations] = useState<
    MenuVendorLocationWithDeliveryRegionsFragment[] | null
  >(null)
  const [availableVendorLocations, setAvailableVendorLocations] = useState<
    MenuVendorLocationWithDeliveryRegionsFragment[]
  >([])
  const router = useRouter()
  const locale = useLocale()
  const apolloClient = useApolloClient()
  const [userState, setUserState] = useLocalStorage<UserBasketSettings>(
    'userBasketSettings',
    defaultUserState,
  )
  const useMinOrderValueExTax = useFeatureFlag(SPLITS.MIN_ORDER_VALUE_EX_TAX)
  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)

  const [basketIsFeedrAdminApproved, setBasketIsFeedrAdminApproved] = useState(false)

  const updateErrors = (newErrors: AppErrors) => {
    const newErrorsCopy = _.omitBy(newErrors, _.isNull)
    setErrors({ ...newErrorsCopy })
  }

  const updateUserState = (newUserState: Partial<UserBasketSettings>) => {
    const newState = _.cloneDeep({ ...defaultUserState, ...userState, ...newUserState })
    if (_.isEqual(userState, newState)) return
    setUserState(newState)
  }

  const updateDeliveryFee = (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => {
    const newDeliveryFee = sortBy(
      vendorLocation.deliveryRegions,
      (region) => region.orderPosition,
    ).find((d) => d.serviceType === ServiceType.Gm)?.deliveryFee?.total
    if (!newDeliveryFee && newDeliveryFee !== 0) throw new Error('Delivery fee not found')
    setDeliveryFee(newDeliveryFee)
  }

  const updateMinOrderValue = (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => {
    const region = sortBy(vendorLocation.deliveryRegions, (region) => region.orderPosition).find(
      (d) => d.serviceType === ServiceType.Gm,
    )
    const minOrderVal = useMinOrderValueExTax ? region?.minOrderValueExTax : region?.minOrderValue
    const newMinOrderValue =
      minOrderVal ??
      // safeguard against delivery regions without minOrderValue
      (minOrderVal ? minOrderVal * 100 : null)
    if (!newMinOrderValue && newMinOrderValue !== 0)
      console.error(`Minimum order value for region ${region?.id} not found`)
    setMinOrderValue(newMinOrderValue)
    setMinOrderValueExTax(newMinOrderValue)
  }

  const validate = () => {
    if (!inRangeVendorLocations || !userState) return false
    const activeLocations = getCutOffLocations(inRangeVendorLocations, cutOff5pm)
    const { newErrors, chosenVendorLocations } = validateAll({
      inRangeVendorLocations: activeLocations,
      userState,
      basketIsFeedrAdminApproved,
      useDeliveryTimes: cutOff5pm,
    })
    setAvailableVendorLocations(chosenVendorLocations)
    if (chosenVendorLocations.length) {
      setSelectedVendorLocation(chosenVendorLocations[0])
      updateDeliveryFee(chosenVendorLocations[0])
      updateMinOrderValue(chosenVendorLocations[0])
    }
    if (!_.isEqual(newErrors, errors)) setErrors(newErrors)
    return !Object.values(newErrors).length
  }

  useEffect(() => {
    validate()
  }, [inRangeVendorLocations])

  useEffect(() => {
    void validate()
  }, [userState])

  const { postcode, street, date, time } = routerQuerySchema.parse(router.legacyQuery) // router.query doesn't have this search params, use legacyQuery for now.

  useEffect(() => {
    if (router.pathname !== '/[domain]/[lang]/office-catering/vendors') return
    const collectDetails = async () => {
      let newLocation: UserBasketSettingsLocationFragment | null = null
      if (postcode && street) {
        const foundAddress = await lookupAddressDetails(
          { street, postcode, city: '' },
          apolloClient,
          true,
          locale,
        )
        if (foundAddress) newLocation = foundAddress as UserBasketSettingsLocationFragment
      }
      let minDateTime: moment.Moment | null = null
      if (date) {
        minDateTime = moment(date)
        if (time) {
          const [hours, minutes] = time.split(':')
          minDateTime.add(hours, 'hours').add(minutes, 'minutes')
        }
      }
      updateUserState({
        initalised: true,
        location: newLocation || userState?.location,
        ...(minDateTime && { date: minDateTime.valueOf() }),
        ...(minDateTime &&
          time && {
            time: `${minDateTime.format('HH:mm')} - ${minDateTime
              .add(30, 'minutes')
              .format('HH:mm')}`,
          }),
      })
    }
    void collectDetails()
  }, [])

  return {
    userState: { ...defaultUserState, ...userState },
    updateUserState,
    selectedVendorLocation,
    setSelectedVendorLocation,
    deliveryFee,
    minOrderValue,
    minOrderValueExTax,
    updateDeliveryFee,
    updateMinOrderValue,
    setInRangeVendorLocations,
    validate,
    availableVendorLocations,
    setAvailableVendorLocations,
    setBasketIsFeedrAdminApproved,
    errors,
    setErrors: updateErrors,
  }
}

// @ts-expect-error unknown
export const AppStateProvider: React.FC = ({ children }) => {
  const state = useAppStateImplementation()

  return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
}

/**
 * @deprecated We realised that using global context causes preformance and code readability issues.
 * Hence we are incrementally migrating bits away from this state.
 * Please try to access values from alternative states (e.g. useUserBasketSettingsState)
 * or if adding new logic then aim to add it elsewhere.
 */
export const useAppState = (): AppState => {
  const { throwAppError } = useAppError()
  const state = useContext(AppStateContext)

  if (!state)
    return throwAppError({ type: 'REACT_CONTEXT_USED_OUTSIDE_PROVIDER', name: 'AppState' })

  return state
}
